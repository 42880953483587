<template>
  <b-modal
    :id="id"
    hide-header
    hide-footer
    centered
    size="xl"
    @hide="closeModal"
    modal-class="modalAgendamento"
    :no-enforce-focus="true"
  >
    <div class="header-actions">
      <Close class="close-icon" @click="closeModal" />
    </div>
    <Tab
      :index="currentTabIndex"
      v-bind:keys="keys"
      v-on:onChange="onChangeTab"
    >
      <AppointmentContent
        :style="{ display: currentTabIndex === 0 ? 'block' : 'none' }"
        :appointmentId="appointmentId"
        :professionalId="professionalId"
        :date="date"
        :loadAppointments="loadAppointments"
        @setPatient="setPatient"
        :patientProps="patientProps"
        @created-appointment="createdAppointment"
        @deleted-appointment="deletedAppointment"
        @set-surgery-procedures="setSurgeryProcedures"
        @updated-appointment="updatedAppointment"
        @appointmentDate="appointmentDateHandler"
        :withEquipments="Boolean(surgeryProcedures?.length)"
        :setAppointmentId="setAppointmentId"
      />
      <SurgeryInformation
        v-if="currentTabIndex === 1"
        :patientId="patient?.id"
        :appointmentId="appointment?.id"
        :surgeryProcedures="surgeryProcedures"
      />
      <b-tooltip
        v-if="!appointmentId"
        target="surgery-information-tab"
        placement="top"
        triggers="hover"
      >
        Salve o agendamento para habilitar esta aba.
      </b-tooltip>

      <AccountContent
        v-if="currentTabIndex === 2"
        :patient="patient"
        :appointmentDate="appointmentDate"
      />

      <AppointmentHistory
        v-if="currentTabIndex === 3"
        :patient="patient"
        @openAppointment="openAppointment"
      />

      <AppointmentLogs
        v-if="currentTabIndex === 4"
        :appointmentId="appointmentId"
      />
    </Tab>
  </b-modal>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { showFeature } from '@/utils/permissionsHelp';

export default {
  name: 'AppointmentModal',
  props: {
    professionalId: {
      type: Object,
      default: () => ({}),
    },
    appointmentId: String,
    date: Date,
    patientProps: {
      type: Object,
      default: () => ({}),
    },
    setAppointmentId: Function,
    id: {
      type: String,
      default: 'appointment-modal',
    }
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Tab: () => import('@/components/Tab.vue'),
    AppointmentContent: () => import('./AppointmentContent'),
    AccountContent: () => import('./AccountContent'),
    AppointmentHistory: () => import('./AppointmentHistory'),
    AppointmentLogs: () => import('./AppointmentLogs.vue'),
    SurgeryInformation: () => import('./SurgeryInformation'),
  },

  data() {
    return {
      printingResume: false,
      currentTabIndex: 0,
      patient: null,
      surgeryProcedures: [],
      appointment: {},
      appointmentDate: ''
    }
  },
  computed: {
    keys() {
      return [
        'Agendamento',
        { label: 'Informações da cirurgia', disabled: !this.appointment?.id, id: 'surgery-information-tab', hidden: !this.surgeryProcedures.length },
        { label: 'Conta', disabled: !this.patient },
        { label: 'Histórico de agendamentos', disabled: !this.patient },
        { label: 'Histórico de ações', disabled: !this.patient },
      ].filter((key) => key)
    },
  },
  methods: {
    showFeature,
    openAppointment(appointmentId) {
      this.currentTabIndex = 0
      this.$emit('openAppointment', appointmentId);
    },

    closeModal() {
      this.patient = null
      this.currentTabIndex = 0
      this.$bvModal.hide(this.id)
      this.$emit('onHidden')
    },
    onChangeTab(index) {
      this.currentTabIndex = index;
    },
    loadAppointments() {
      this.$emit('loadAppointments')
    },
    setPatient(patient) {
      this.patient = patient
    },
    createdAppointment(appointment) {
      this.appointment = appointment
      this.$emit('created-appointment', appointment)
    },
    updatedAppointment(appointment) {
      this.$emit('updated-appointment', appointment)
    },
    deletedAppointment(appointmentId) {
      this.closeModal();
      this.$emit('deleted-appointment', appointmentId)
    },
    setSurgeryProcedures(value) {
      this.surgeryProcedures = value.map(procedure => {
        return {
          temp_id: uuidv4(),
          name: procedure.name,
          laterality: null,
          clinic_procedure_id: procedure.id,
          participants: [
            {
              type: 'Executante',
              beneficiary_id: null
            },
            {
              type: 'Instrumentador(a)',
              beneficiary_id: null
            },
            {
              type: 'Anestesista',
              beneficiary_id: null
            }
          ],
          clinic_id: procedure.clinic_id,
          type: procedure.type,
          item_id: procedure.id,
          item_type: procedure.item_type || 'App\\Models\\TissGuide\\TissTableProcedure',
          item: {
            type: 'SURGICAL'
          }
        }
      })
    },
    appointmentDateHandler(val) {
      this.appointmentDate = val
    }
  },
  watch: {
    appointmentId(appointmentId) {
      this.$set(this.appointment, 'id', appointmentId)
    }
  }
}
</script>
<style lang="scss">
.modalAgendamento {
  border-radius: 8px;
  .header-actions {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 50;
    display: inline-flex;
    justify-content: center;
    gap: 1rem;

    .print-icon {
      stroke: var(--blue-500);
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    .close-icon {
      fill: var(--neutral-500);
      height: 24px;
      width: 24px;
    }
  }
  .modal-content {
    .modal-body {
      padding: 0;

      .header {
        border-bottom: 1px solid var(--neutral-200);
        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px 24px;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }
          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }
      .body {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
